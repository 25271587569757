import React from "react";
import "./App.css";
import profilePic from "./midia/copia-perfil.png"
import logo from './midia/logo-remove.png';
import instagramIcon from './midia/instagram.png';
import whatsappIcon from './midia/whatsapp.png';

function App() {
  return (
    <div className="bio-page">
      <header className="header">
        <div className="header-bg"></div>
        <img
          className="profile-pic"
          src={profilePic}
          alt="Foto de perfil"
        />
        <h1 className="name">
          
          <img src={logo} alt="Logo" style={{ width: '350px', height: 'auto' }} />
        </h1>

        <div className="icons">
          <a href="https://www.instagram.com/_olsdebora" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://wa.me/5585986156326?text=Ol%C3%A1+%F0%9F%91%8B" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
        </div> 
      </header>
      <main className="links">
      
        <a href="https://wa.me/5585986156326?text=Ol%C3%A1+%F0%9F%91%8B" className="link-card">
          Agende aqui sua avaliação 💎💉
        </a>
      </main>
    </div>
  );
}

export default App;
